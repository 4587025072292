import Session from 'supertokens-web-js/recipe/session';
//import SuperTokens from 'supertokens-web-js';
//import EmailPassword from "supertokens-web-js/recipe/emailpassword";
//import backendRequest from "@/services/backendRequest";
import { SuperTokensController as STC } from './SuperTokensController.js';

STC.init();
async function getUser(/*link = null*/) {
    // if (link !== null) {
    //     await linkAuth(link);
    // }
    let bOpus_user = localStorage.getItem("bOpus_user");
    let user;
    if (bOpus_user !== null) {
        user = await getUserProfile(JSON.parse(bOpus_user));
    } else {
        user = await getUserProfile();
    }
    return user;
}

async function getUserProfile(user = null) {
    //await new Promise(resolve => setTimeout(resolve, 5000));
    let checkSession = await Session.doesSessionExist();
    console.log('checkSession', checkSession);
    if (!checkSession) {
        if (window.location.pathname.includes('/authLink/')) {
            let link = window.location.pathname.replace('/authLink/', '');
            if (link !== '') {
                //console.log('checkAuthLink', link);
                localStorage.setItem('linkAuth', link);
                window.location.href = "/login";
                //await linkAuth(pathArray[1]);
            }
            // const pathArray = window.location.pathname.split('/').filter(element => element !== '');
            // if (pathArray[0] === 'authLink' && pathArray[1] !== undefined) {
            //     console.log('checkAuthLink', pathArray[1]);
            //     localStorage.setItem('linkAuth', pathArray[1]);
            //     window.location.href = "/login";
            //     //await linkAuth(pathArray[1]);
            // }
        }
        return null;
    }

    return await STC.getCurrentUser().then(async (response) => {
        console.log('checkResponse', response);
        if (response.message === 'unauthorised') {
            return null;
            // if (!window.location.pathname.includes('/authLink/')) {
            //     return null;
            // }
            // const pathArray = window.location.pathname.split('/').filter(element => element !== '');
            // if (pathArray[0] !== 'authLink' || pathArray[1] === undefined) {
            //     return null;
            // }
            // return await linkAuth(pathArray[1]);
        }
        if (response.roles.length < 1 || response.roles.includes('disabled')) {
            await STC.logout();
            return null;
        }
        if (user === null) {
            user = {};
        }
        user.metadata = response.metadata;
        user.token = response.token;
        user.role = response.roles;//user.metadata.role; //todo use user.role instead of user.metadata.role
        user.metadata.name = user.metadata.name !== undefined ? user.metadata.name: '';
        user.metadata.surname = user.metadata.surname !== undefined ? user.metadata.surname: '';
        user.changePass = user.metadata.changePass !== undefined ? user.metadata.changePass: '';
        localStorage.setItem("bOpus_user", JSON.stringify(user));
        if (user.metadata.exposedTreeNodes !== undefined) {
            localStorage.setItem("exposedTreeNodes", JSON.stringify(user.metadata.exposedTreeNodes));
        }
        console.log('checkUserInfo', user);
        return user;
    }).catch((error) => {
        console.log('ST error:', error);
        return user;
    });
}

// async function linkAuth(link) {
//     let info = await backendRequest(
//         'authLink/decrypt',
//         {
//             "data": link
//             //"show_errors": 'true'
//         }
//     );
//     console.log('wtf1', info);
//     if (info.login === undefined || info.password === undefined) {
//         window.location.href = "/login";
//     }
//     console.log('wtf2', info);
//     console.log('wtf3', info.login, info.password);
//     let result = await STC.signIn(info.login, info.password).then(async (response) => {
//         if (response.result) {
//             if (info.link === undefined) {
//                 window.location.href = "/login";
//             } else {
//                 window.location.href = info.link;
//             }
//         } else {
//             alert(response.message);
//             window.location.href = "/login";
//         }
//
//         return null;
//     });
//
//     if (result.result && result.data !== undefined) {
//         return result.data;
//     }
//
//     return null;
// }

export default getUser;
